import logo from '../logo.svg';
import '../styles/Main.css';
import { Link } from 'react-router-dom';
import React, { Component, useContext } from 'react';
import BottomNavigation from "./BottomNavigation";
import NavigationBarMobile from "./NavigationBarMobile";



// console.log(data);
// { console.log(FetchString( 'http://localhost:8000/data-home' )) }

function NavigationMobile() {




    // Keys nececairy here?

    return [
        <NavigationBarMobile key={1}/>,
        <BottomNavigation key={2}/>
    ];
}

export default NavigationMobile;
