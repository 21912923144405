import { useLanguage } from '../LanguageContext';
import getString from "../getStrings";
import ContactForm from "../ContactForm";
import instagram from "../assets/icons/instagram.svg";
import linkedin from "../assets/icons/linkedin.svg";
import whatsapp from "../assets/icons/whatsapp.svg";
import {Link} from "react-router-dom";

const SocialsButtons = () => {
    const { language } = useLanguage();
    const sharedTerm = 'generic.terminologyAndShared';

    const linksContainer = [
        {
            category: 'socials',
            data: {
                links: [
                    { title: `${getString(`${sharedTerm}.instagram`, language)}`, url: "/", image: instagram, alt: "Instagram Icon" },
                    { title: `${getString(`${sharedTerm}.linkedin`, language)}`, url: "/", image: linkedin, alt: "LinkedIn Icon" },
                    { title: `${getString(`${sharedTerm}.whatsapp`, language)}`, url: "/", image: whatsapp, alt: "WhatsApp Icon" }
                ]
            }
        }
    ];

    return (
        <div className="socialsContainer">
            {/* Access the first item in the array and check if links exist */}
            {linksContainer[0]?.data?.links && (
                <>
                    {/* Map through the links array */}
                    {linksContainer[0].data.links.map((link, index) => (
                        <div className="socialIcon" key={index}>
                            <Link to={link.url} className={link.title}>
                                <img src={link.image} alt={link.alt} />
                            </Link>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}

export default SocialsButtons;