import React from 'react';
import { ReactComponent as UserIcon } from './assets/icons/user.svg';
import { ReactComponent as PaletteIcon } from './assets/icons/palette.svg';
import { ReactComponent as EnvelopeIcon } from './assets/icons/envelope.svg';
import { ReactComponent as StarIcon } from './assets/icons/favorite.svg';
import { ReactComponent as CameraIcon } from './assets/icons/photography.svg';
import { ReactComponent as TagIcon } from './assets/icons/tag-1.svg';
import { ReactComponent as CodeIcon } from './assets/icons/html-1.svg';
import { ReactComponent as CheckIcon } from './assets/icons/check-1.svg';
import { ReactComponent as CloseIcon } from './assets/icons/error-1.svg';
import { ReactComponent as UnlockIcon } from './assets/icons/unlocked.svg';
import { ReactComponent as ArrowLeftIcon } from './assets/icons/arrow-left-1.svg';

const icons = {
    about: UserIcon,
    skills: StarIcon,
    portfolio: PaletteIcon,
    contact: EnvelopeIcon,
    camera: CameraIcon,
    tag: TagIcon,
    code: CodeIcon,
    check: CheckIcon,
    close: CloseIcon,
    unlocked: UnlockIcon,
    arrowLeft: ArrowLeftIcon,
};

const CustomIcon = ({ name, size = 64, color = '#00aead' }) => {
    const IconComponent = icons[name];
    return IconComponent ? <IconComponent width={size} height={size} fill={color} /> : null;
};

export default CustomIcon;