import React, { useEffect } from 'react';
import logo from '../../logo.svg';
import '../../styles/Main.css';
import { useLanguage } from "../../LanguageContext";

const PhoneSplicer = () => {
    useEffect(() => {
        const phoneElem = document.querySelector('.phone');
        const countryCode = phoneElem.getAttribute('data-country-code');
        const number = phoneElem.getAttribute('data-number');
        phoneElem.innerHTML = `<span>${countryCode} ${number}</span>`;
    }, []); // Empty dependency array to run only once on mount

    return (
        <div>
            <span className="phone" data-country-code="+31" data-number="624238116"></span>
        </div>
    );
};

export default PhoneSplicer;