import { useLanguage } from '../LanguageContext';
import getString from "../getStrings";
import ContactForm from "../ContactForm";
import ContentHeaderSkills from "../content/SkillsHeader";
import skillImages from "../assets/images/skillsImages.svg";
import React from "react";
import GenericSkillSection from "../content/GenericSkillSection";
import ContentFooter from "../content/ContentFooter";
import "./contact.scss";
import PageTitle from "../components/PageTitle/PageTitle";
import ContactFormulier from "../components/RecapchaForm/Form";
import EmailSplicer from "../components/EmailSplicer/EmailSplicer";
import PhoneSplicer from "../components/PhoneSplicer/PhoneSplicer";

const Contact = () => {

    const { language } = useLanguage();
    const pageName = "generic.terminologyAndShared";
    const contactName = "contact";

    return (
        <div className="contentWrapper contact">
            <PageTitle title={getString(`generic.terminologyAndShared.contact`, language)} />

            {/* Header */}
            <div className={"innerWrapper blue"}>
                <div className={"innerContent"}>
                    <div className="contactWrapper">
                        <div className="contactIntro">
                            <h2 className="Contact">{getString(`${contactName}.introText.title`, language)}</h2>

                            <p>{getString(`${contactName}.introText.data`, language)}</p>

                            <div className="contactInfoContainer">
                                <h3 className="Contact">{getString(`${pageName}.email.title`, language)}</h3>
                                <p><EmailSplicer /></p>
                            </div>

                            <div className="contactInfoContainer">
                                <h3 className="Contact">{getString(`${pageName}.phone.title`, language)}</h3>
                                {/*<p>{getString(`${pageName}.phone.data`, language)}</p>*/}
                                <p><PhoneSplicer /></p>
                            </div>

                        </div>

                        <ContactFormulier />


                        {/*<ContactForm/>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;