import {useLanguage} from "../LanguageContext";
import React from "react";
import getString from "../getStrings";
import BottomNavigationLink from "../navigation/BottomNavigationLink";
import {Link} from "react-router-dom";
import CustomIcon from "../Icons";
import PageContent from "../getContentBlock";





const ContentBlock = ({ page, section, headerType, children }) => {

    const { language } = useLanguage();

    return (
        <div className="ContentBlock">
            {/* Pass the props to the PageContent component */}
            <PageContent
                page={page}
                section={section}
                headerType={headerType}
                language={language}
            />

            {children}
        </div>
    );
};

export default ContentBlock;