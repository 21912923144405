import logo from './logo.svg';
import './styles/Main.css';
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import NavigationDesktop from "./navigation/NavigationDesktop";
import NavigationMobile from "./navigation/NavigationMobile";



const Header = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1450);

    const updatePredicate = () => {
        setIsDesktop(window.innerWidth > 1450);
    };

    useEffect(() => {
        // Set up the resize event listener
        window.addEventListener('resize', updatePredicate);
        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updatePredicate);
        };
    }, []); // Empty dependency array ensures this runs only once

    return (
        <div>
            <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
            {isDesktop ? (
                <NavigationDesktop />
            ) : (
                <NavigationMobile />
            )}
        </div>
    );
};

export default Header;