import strings from './data/strings.json';
import getString from "./getStrings";
import React from "react";

const DynamicHeading = ({ headerType, children }) => {
    // Validate that headerType is a valid heading tag
    const validHeadings = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
    const HeadingTag = validHeadings.includes(headerType) ? headerType : 'h1'; // Fallback to h1 if invalid

    return React.createElement(HeadingTag, {}, children);
};

const PageContent = ({ page, title, section, headerType, language }) => {
    // Filter the page from the data
    // Split the page by dots to handle nested access
    const keys = page.split('.');

    // Traverse the nested structure of strings.data[0]
    let pageData = strings.data[0];
    keys.forEach(key => {
        pageData = pageData?.[key]?.[0];  // Traverse to the next level
    });

    // Check if page exists
    if (!pageData) {
        return <p>Page not found</p>;
    }

    // If section is present (like headerContent), render its content
    const sectionContent = pageData[section]?.[0];

    // Check if section exists
    if (!sectionContent) {
        return <p>Section not found</p>;
    }

    return (
        <div className={'getContentBlockContainer'}>
            <DynamicHeading headerType={headerType}>{sectionContent.title?.[0][language]}</DynamicHeading>
            {Object.keys(sectionContent)
                .filter(key => key.startsWith('paragraph'))
                .map((key, index) => (
                    <p className={'itemnumber'+index} key={index}>{sectionContent[key][0][language]}</p>
                ))}
        </div>
    );
};

export default PageContent;
