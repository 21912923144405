import React, { createContext, useState, useContext } from 'react';

// Create a Context for the language
import { RetrieveBrowserLanguage } from "./RetrieveBrowserLanguage";
import {setCookie} from "./setCookie";

const LanguageContext = createContext();

// Custom hook to use language context
export const useLanguage = () => useContext(LanguageContext);


// console.log("Test: " + useLanguage)

// Provider component to wrap the app
export const LanguageProvider = ({ children }) => {

    const [language, setLanguage] = useState(RetrieveBrowserLanguage()); // Default language is 'en'

    const switchLanguage = (lang) => {
        let userLanguage = lang.includes('NL') ? 'NL' : 'EN'; // Example to handle language codes like 'nl-NL'


        // console.log('language switch is:');
        // console.log(userLanguage);
        // Store the language in a cookie for 31 days
        setCookie('language', userLanguage, 31);

        setLanguage(lang);
    };


    // console.log('Rerender');
    return (
        <LanguageContext.Provider value={{ language, switchLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};