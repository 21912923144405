import logo from '../../logo.svg';
import '../../styles/Main.css';
import {useLanguage} from "../../LanguageContext";
import ContentBlock from "../../content/ContentBlock";
import getString from "../../getStrings";
import FooterLinks from "./FooterLinks";
import uxd from "../../assets/images/uxd.jpg";

import whatsapp from "../../assets/icons/whatsapp.svg";
import instagram from "../../assets/icons/instagram.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import EmailSplicer from "../EmailSplicer/EmailSplicer";

const pageName = 'footer';
const Footer = () => {

    const { language } = useLanguage();

    const pathPrefixData = 'generic.footerContent.data';
    const pathPrefixContact = 'generic.footerContent.contact';
    const pathPrefixSocials = 'generic.footerContent.socials';
    const pathPrefixLinks = 'generic.footerContent.links';
    const sharedTerm = 'generic.terminologyAndShared';

    const footerLinks = [
        {
            category: 'data',
            data: {
                title: `${getString(`${pathPrefixData}.title`, language)}`,
                content: {
                    withPrefix: [
                        {
                            title: `${getString(`${pathPrefixData}.kvk.title`, language)}`,
                            data: `${getString(`${pathPrefixData}.kvk.data`, language)}`
                        },
                        {
                            title: `${getString(`${pathPrefixData}.btw.title`, language)}`,
                            data: `${getString(`${pathPrefixData}.btw.data`, language)}`
                        },
                    ],
                    withoutPrefix: [
                        { title: `${getString(`${pathPrefixData}.address.line1`, language)}` },
                        { title: `${getString(`${pathPrefixData}.address.line2`, language)}` }
                    ]
                },
                link: "/",
                alt: "Image 1",
                links: [
                    { title: `${sharedTerm}.about.title`, link: "/", image: '', alt: "Image 1" },
                    { title: "Portfolio", link: "/", image: '', alt: "Image 2" },
                    { title: "Skills", link: "/", image: '', alt: "Image 3" },
                    { title: "Contact", link: "/", image: '', alt: "Image 3" }
                ]
            }
        },
        {
            category: 'contact',
            data: {
                title: `${getString(`${pathPrefixContact}.title`, language)}`,
                content: {
                    title: `${getString(`${pathPrefixContact}.email.title`, language)}`
                },
                link: "/",
                alt: "Image 1",
                links: [
                    { title: "About", link: "/", image: '', alt: "Image 1" },
                    { title: "Portfolio", link: "/", image: '', alt: "Image 2" },
                    { title: "Skills", link: "/", image: '', alt: "Image 3" },
                    { title: "Contact", link: "/", image: '', alt: "Image 3" }
                ]
            }
        },
        // {
        //     category: 'socials',
        //     data: {
        //         title: `${getString(`${pathPrefixSocials}.title`, language)}`,
        //         content: {
        //             title: `${getString(`${pathPrefixSocials}.email.title`, language)}`
        //         },
        //         link: "/",
        //         alt: "Image 1",
        //         links: [
        //             { title: `${getString(`${sharedTerm}.instagram`, language)}`, url: "/", image: instagram, alt: "Image 1" },
        //             { title: `${getString(`${sharedTerm}.linkedin`, language)}`, url: "/", image: linkedin, alt: "Image 2" },
        //             { title: `${getString(`${sharedTerm}.whatsapp`, language)}`, url: "/", image: whatsapp, alt: "Image 3" }
        //         ]
        //     }
        // },
        {
            category: 'links',
            data: {
                title: `${getString(`${pathPrefixLinks}.title`, language)}`,
                content: {
                    title: `${getString(`${pathPrefixLinks}.email.title`, language)}`
                },
                link: "/",
                alt: "Image 1",
                links: [
                    { title: `${getString(`${sharedTerm}.about`, language)}`, url: "/about", image: '', alt: "Image 1" },
                    { title: `${getString(`${sharedTerm}.portfolio`, language)}`, url: "/portfolio", image: '', alt: "Image 1" },
                    { title: `${getString(`${sharedTerm}.skills`, language)}`, url: "/skills", image: '', alt: "Image 1" },
                    { title: `${getString(`${sharedTerm}.contact`, language)}`, url: "/contact", image: '', alt: "Image 1" }
                ]
            }
        }
        // Add more skills and their links
    ];

    // console.log(footerLinks);



    return (
        <footer className="footer footerWrapper trueFooter">
            <div className="innerWrapper grayNeutral trueInnerFooter">

                <div className="footerInfo innerContent ">
                    {/*{ContentBlock('generic', 'footer', 'h3')}*/}

                    <FooterLinks sections={footerLinks} links={'test'} />



                    {/*<button className="blue">*/}
                    {/*    Contact me*/}
                    {/*</button>*/}

                    {/*<div className="socials">*/}
                    {/*    <button className="instagram"></button>*/}
                    {/*    <button className="linkedIn"></button>*/}
                    {/*    <button className="whatsapp"></button>*/}
                    {/*</div>*/}
                </div>



                <div className="copyright grayDark">
                    {getString(`generic.footerCopyright.title`, language)}
                    {/*{getString(`generic.footerCopyright.title`, language)} - {(new Date().getFullYear())}*/}
                </div>
            </div>
        </footer>
    );
}

export default Footer;
