export function setCookie(name, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();

    // Determine domain based on hostname
    const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
    const domain = isLocalhost ? "localhost" : ".cellular-design.com";

    // Construct the cookie string
    document.cookie = `${name}=${value}; ${expires}; path=/; domain=${domain}; Secure; SameSite=None`;
}
