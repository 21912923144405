import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import getString from "../../getStrings";
import { useLanguage } from '../../LanguageContext';
import check from "../../assets/icons/check-1.svg";
import CustomIcon from "../../Icons";

const ContactFormulier = () => {
    const { language } = useLanguage();
    const pageName = "contact";

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [isPending, setIsPending] = useState(false);
    const [sent, setIsSent] = useState(false);

    const [errors, setErrors] = useState({});

    const navigate = useNavigate();
    const redirectSuccess = event => navigate('/', { replace: true });


    const Required = () => {
        return (
            <span className="required" aria-hidden="true" style={{ color: 'red' }}>
                *
            </span>
        )
    }


    const handleSubmit = async (e) => {
        e.preventDefault();



        setIsPending(true);
        let location = 'live';

        const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
        let fileLocation = null;
        if (isLocalhost) {
            fileLocation = 'http://cellular-design/cellularEmailHandler.php';
            location = 'test';
        } else {
            fileLocation = 'https://www.cellular-design.com/backend/cellularEmailHandler.php';
        }

        const headers = {
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
        };

        let title = document.getElementById("titleField").value;

        const formSubmission = { firstName, lastName, email, phoneNumber, subject, message, location, title };

        try {
            const response = await fetch(fileLocation, {
                method: 'POST',
                headers: headers,
                // headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ ...formSubmission}),
                mode: 'cors' // Ensures CORS is enabled (this is often the default)
            });

            const data = await response.json();

                if (data.success) {
                    console.log('Email sent successfully!');
                    setErrors({}); // Clear errors on success
                    setIsSent(true);
                } else {
                    // Update error states
                    if (data.errors) {
                        setErrors(data.errors);
                        setIsPending(false);
                    } else {
                        console.log("Failed to send email:", data.message);
                    }
                }
        } catch (error) {
            console.error("An error occurred while sending the email:", error);
        }
    };

    return (
        <div className={'contactFormContainer'}>
            {sent &&
                <div className={'feedbackWindow'}>
                    <h4>{getString(`${pageName}.submit.success`, language)}</h4>
                    <div className={'checkCircle'}>
                        <CustomIcon className={'feedbackCheck'} name={'check'} />
                    </div>
                </div>
            }
            {!sent &&
                <div className="contactForm">
                    <form action="" onSubmit={handleSubmit} id={"contactForm"}>
                        <input
                            id={"titleField"}
                            className={"titleField"}
                            type="text"
                            name={"title"}
                            value={''}
                            tabIndex="-1"
                            autoComplete="off"
                        />
                        <div className="formGroup">
                            <div className="formField">
                                <label
                                    htmlFor="firstName">{getString(`${pageName}.firstName.label`, language)}<Required/></label>
                                <input
                                    type="text"
                                    required={true}
                                    name={"firstName"}
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    placeholder={getString(`${pageName}.firstName.placeholder`, language)}
                                />
                            </div>

                            <div className="formField">
                                <label htmlFor="">{getString(`${pageName}.lastName.label`, language)}<Required/></label>
                                <input
                                    type="text"
                                    required={true}
                                    name={"lastName"}
                                    placeholder={getString(`${pageName}.lastName.placeholder`, language)}
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="formField">
                            <label htmlFor="">{getString(`${pageName}.email.label`, language)}<Required/></label>
                            <input
                                type="email"
                                required={true}
                                name={"emailAdress"}
                                placeholder={getString(`${pageName}.email.placeholder`, language)}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        <div className="formField">
                            <label htmlFor="">{getString(`${pageName}.phoneNumber.label`, language)}<Required/></label>
                            <input
                                type="text"
                                required={true}
                                name={"phoneNumber"}
                                placeholder={getString(`${pageName}.phoneNumber.placeholder`, language)}
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                        </div>

                        <div className="formField">
                            <label htmlFor="">{getString(`${pageName}.subject.label`, language)}<Required/></label>
                            <input
                                type="text"
                                required={true}
                                name={"subject"}
                                placeholder={getString(`${pageName}.subject.placeholder`, language)}
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                            />
                        </div>


                        <div className="formField">
                            <label htmlFor="">{getString(`${pageName}.message.label`, language)}<Required/></label>
                            <textarea
                                required={true}
                                name={"message"}
                                placeholder={getString(`${pageName}.message.placeholder`, language)}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </div>


                        {errors.hp && <span className="error-message">{errors.hp}</span>}
                        {errors.hp && <span className="error-message">{getString(`${pageName}.submit.hp`, language)}</span>}

                        <div className="formField">
                            {!isPending && <button className="button blue"
                                type={"submit"}>{getString(`${pageName}.submit.label`, language)}</button>}
                            {isPending && <button className="button" disabled
                                                  type={"submit"}>{getString(`${pageName}.submit.loading`, language)}</button>}

                            {/* Mount your reCAPTCHA component */}
                            {/*<MyRecaptchaComponent />*/}

                        </div>
                    </form>
                </div>
            }
        </div>
    );
};

export default ContactFormulier;
