import React from "react";
import projectTileSmall from "../content/projectTileSmall"; // Ensure correct import for projectTileSmall
import { useLanguage } from '../LanguageContext';
import getString from "../getStrings";
import ProjectTileSmall from "../content/projectTileSmall";

const SkillProjectHighlightSection = ({ skillItem, skillIndex }) => {
    const { language } = useLanguage();

    return (
        <>
            {skillItem.links.map((linkItem, linkIndex) => (
                <React.Fragment key={linkIndex}>
                    {/* Render projectTileSmall */}
                    <ProjectTileSmall index={skillIndex} name={linkItem.title} link={linkItem.link} image={linkItem.image} alt={linkItem.alt} />

                </React.Fragment>
            ))}
        </>
    );
};

export default SkillProjectHighlightSection;
