import { getCookie } from "./getCookie";
import { setCookie } from "./setCookie";

export function RetrieveBrowserLanguage(){

    // Check if the language is already stored in a cookie
    const cookieLanguage = getCookie('language');

    // If the cookie exists, return it
    if (cookieLanguage) {
        // console.log("Cookie set, language is: " + cookieLanguage);
        return cookieLanguage;
    }
    // console.log("Cookie not set");

    const browserLanguage = navigator.language || navigator.userLanguage;
    let userLanguage = browserLanguage.includes('NL') ? 'NL' : 'EN'; // Example to handle language codes like 'nl-NL'

    // console.log(userLanguage);

    // Store the language in a cookie for 31 days
    setCookie('language', userLanguage, 31);

    return userLanguage;
}
