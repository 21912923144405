import React from 'react';
import { useLanguage } from './LanguageContext';
import englishFlag from './assets/icons/EN.svg'; // adjust the path as needed
import dutchFlag from './assets/icons/NL.svg'; // adjust the path as needed
import {getCookie} from "./getCookie";

// import { RetrieveBrowserLanguage } from "./RetrieveBrowserLanguage";

const LanguageSwitcher = () => {

    const { language, switchLanguage } = useLanguage();


    return (
        <div className="languageButton">
            {language === "NL" ? (
                <button onClick={() => switchLanguage('EN')}>
                    <img className="mobileLogo" src={englishFlag} alt="Logo" />
                </button>
            ) : (
                <button onClick={() => switchLanguage('NL')}>
                    <img className="mobileLogo" src={dutchFlag} alt="Logo" />
                </button>
            )}


        </div>
    );
};

export default LanguageSwitcher;