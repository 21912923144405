import React from 'react';
import {Link} from "react-router-dom";
import ContentBlock from "./ContentBlock";
import skillImages from "../assets/images/skillsImages.svg";
import AngleAsset from "../assets/Angle";

const SkillsHeader = ({ skillImages }) => {

    return (
        <div>
            <div className="innerWrapper green">
                <div className="skills innerContent headerContent ">
                    <ContentBlock page={'skills'} section={'headerContent'} headerType={'h1'}/>

                    <div className="skillsImages">
                        <img className="skillImages" src={skillImages} alt="Logo" />
                    </div>
                </div>
            </div>
            <AngleAsset position={'bottom'} color={'green'} />
        </div>
    );
};

export default SkillsHeader;
