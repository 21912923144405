import logo from '../logo.svg';
import '../styles/Main.css';
import {useLanguage} from "../LanguageContext";
import ContentBlock from "./ContentBlock";
import SocialsButtons from "../navigation/socialsButtons";
import React from "react";
import Button from "../components/Button/Button";
import AngleAsset from "../assets/Angle";
import getString from "../getStrings";


const pageName = 'footer';
const ContentFooter = () => {
    const { language } = useLanguage();


    return (
        <div className="contentFooter outerWrapper">
            <AngleAsset position={'top'} color={'green'} />
            <div className="footerWrapper green">
                <div className="innerWrapper ">

                    <ContentBlock page={'generic'} section={'footer'} headerType={'h3'}/>

                        <Button className={''} color={'blue'} title={getString(`generic.terminologyAndShared.contactButton`, language)} link={'contact'} />

                    {/*<SocialsButtons />*/}

                </div>
            </div>

        </div>
    );
}

export default ContentFooter;
