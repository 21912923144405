import logo from '../logo.svg';
import '../styles/Main.css';
import '../navigation/BottomNavigation.scss';
import {Link, NavLink} from 'react-router-dom';
import BottomNavigationLink from './BottomNavigationLink';
import getString from "../getStrings";
import React from "react";
import {useLanguage} from "../LanguageContext";



const BottomNavigation = () => {


    const { language } = useLanguage();

// Array of button configurations
    const bottomNavItems = [
        { id: 1, title: getString('generic.terminologyAndShared.about', language), location: 'about', image: 'about', alt: 'Test alt' },
        { id: 2, title: getString('generic.terminologyAndShared.portfolio', language), location: 'portfolio', image: 'portfolio', alt: 'Test alt2' },
        { id: 3, title: getString('generic.terminologyAndShared.skills', language), location: 'skills', image: 'skills', alt: 'Test alt3' },
        { id: 4, title: getString('generic.terminologyAndShared.contact', language), location: 'contact', image: 'contact', alt: 'Test alt4' }
    ];


    const color = '#ffffff';

    return (
        <nav className="BottomNavigationMobile">
            {bottomNavItems.map((NavLink) => (
                <BottomNavigationLink
                    key={NavLink.id}
                    title={NavLink.title}
                    link={NavLink.location}
                    image={NavLink.image}
                    alt={NavLink.alt}
                    color={color}
                />
            ))}
        </nav>
    );
}

export default BottomNavigation;