import React, { createContext, useState, useContext } from "react";
import PasswordProtectionOverlayForm from "../PasswordProtectionOverlayForm/PasswordProtectionOverlayForm";

// Create the context
const OverlayContext = createContext();

// Provide the context to the app
export const OverlayProvider = ({ children }) => {
    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    // Open the overlay
    const handleOpenOverlay = () => {
        setOverlayVisible(true);
        setIsClosing(false);
    };

    // Close the overlay with animation
    const handleCloseOverlay = () => {
        setIsClosing(true);
        setTimeout(() => {
            setOverlayVisible(false);
        }, 600); // Delay to allow the fade-out animation to complete
    };

    return (
        <OverlayContext.Provider value={{ isOverlayVisible, handleOpenOverlay, handleCloseOverlay, isClosing }}>
            {children}

            {/* Render the PasswordProtectionOverlayForm if overlay is visible */}
            {isOverlayVisible && <PasswordProtectionOverlayForm onClose={handleCloseOverlay} />}
        </OverlayContext.Provider>
    );
};

// Custom hook to use the Overlay context
export const useOverlay = () => {
    return useContext(OverlayContext);
};
