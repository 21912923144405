import {useLanguage} from "../LanguageContext";
import React from "react";
import getString from "../getStrings";
import BottomNavigationLink from "../navigation/BottomNavigationLink";
import {Link} from "react-router-dom";
import CustomIcon from "../Icons";
import PageContent from "../getContentBlock";
import skillImages from "../assets/images/skillsImages.svg";





const ContentImage = ({ image, alt, className, aspect, children }) => {

    const { language } = useLanguage();

    return (
        <div className={"ContentImage " + `${className}Customizer ` + `${aspect}`}>
            {/* Pass the props to the PageContent component */}
            <img className={className} src={image} alt={alt} />
        </div>
    );
};

export default ContentImage;