import React, {useEffect, useState} from 'react';
import {OverlayProvider, useOverlay} from "../Overlay/OverlayContext";
import { useAuth } from '../../AuthContext'; // Import the AuthContext hook
import "./PasswordProtectionOverlayForm.scss";
import CustomIcon from "../../Icons";
import {setCookie} from "../../setCookie";
import {useNavigate} from "react-router-dom";

const PasswordProtectionOverlayForm = ({ onClose }) => {
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const { isVerified, verifyAuth } = useAuth(); // Use isVerified from context
    const navigate = useNavigate(); // Use navigate for redirection
    const { isClosing } = useOverlay(); // Get the closing state from the overlay context

    // // Disable scrolling when the overlay is open
    // useEffect(() => {
    //     // Disable scroll
    //     document.body.style.overflow = 'hidden';
    //
    //     // Cleanup to enable scroll when component unmounts
    //     return () => {
    //         document.body.style.overflow = 'auto';
    //     };
    // }, []); // Run this effect once when the component mounts and clean up when it unmounts


    const handleSubmit = async (e) => {
        e.preventDefault();


        const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
        const apiUrl = isLocalhost
            ? 'http://localhost/backend_api.php'
            : 'https://www.cellular-design.com/backend_api.php';

        // Send the password to the backend
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
                endpoint: 'verifyContentPassword',
                password: password,
            })
        });

        const result = await response.json();

        console.log(result);

        if (result.status === 'success') {
            setCookie('passwordToken', result.token, 14);
            verifyAuth(); // Set isVerified to true in context
            setErrorMessage('');

            // Scroll to the top of the page after successful password verification
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth', // Adds a smooth scroll effect
            });

            setTimeout(() => {
                onClose();
            }, 500); // Ensure state has time to update
        } else {
            setErrorMessage('Incorrect password');
        }
    };

    const handleCancel = () => {
        navigate('/portfolio'); // Redirect to portfolio if the user cancels
        onClose();
    };

    // this is test asda

    return (
        <div className={`passwordOverlay ${isClosing ? 'fade-out' : ''}`}>

            <button className="close-button elevation100" onClick={handleCancel}>
                <CustomIcon name={'close'} color={'red'}/>
            </button>
            {/* Add Close Button */}

            {!isVerified ? (
                <form onSubmit={handleSubmit}>
                    <h3>Enter access code to proceed</h3>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter password"
                    />
                    <button className={"button blue"} type="submit" onClick={handleSubmit}>Submit</button>
                    {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
                </form>
            ) : (
                <div className={'successfulAccess'}>
                    <div className={'successCircle'}>
                        <CustomIcon name={'check'} color={'red'}/>
                    </div>
                    <h3>Access code verified</h3>
                </div>
            )}
        </div>
    );
};

export default PasswordProtectionOverlayForm;