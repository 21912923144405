import '../styles/Main.css';
import { Link } from 'react-router-dom';
import BottomNavigation from "./BottomNavigation";
import getString from "../getStrings";
import React from "react";
import mobileLogo from '../assets/logo/mobile.svg'; // adjust the path as needed
import LanguageSwitcher from "../LanguageSwitchButton";

function NavigationMobile() {

    return (
        <nav className="NavigationMobile">

            <LanguageSwitcher />

            <div className="logo">
                <Link to="/">
                    <img className="mobileLogo" src={mobileLogo} alt="Logo" />
                </Link>
            </div>

            <div className="NavBarFiller">

            </div>

        </nav>
    );
}

export default NavigationMobile;
