import { Helmet, HelmetProvider } from 'react-helmet-async';
import React from "react";
import logo from "../../assets/images/LogoLinkedIn-06.png";

const PageTitle = ({title}) => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>Cellular-Design - {title}</title>
                <meta name="description" content="Ik ben Thomas Wagteveld, freelance designer and UX - UI Specialist uit Breda."/>

                {/* Facebook Meta Tags */}
                <meta property="og:url" content="https://www.cellular-design.com"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Cellular-Design"/>
                <meta property="og:description" content="Ik ben Thomas Wagteveld, freelance designer and UX - UI Specialist uit Breda."/>
                <meta property="og:image" content={logo} />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image"/>
                <meta property="twitter:domain" content="cellular-design.com"/>
                <meta property="twitter:url" content="https://www.cellular-design.com"/>
                <meta name="twitter:title" content="Cellular-Design"/>
                <meta name="twitter:description" content="Ik ben Thomas Wagteveld, freelance designer and UX - UI Specialist uit Breda."/>
                <meta name="twitter:image" content={logo} />
            </Helmet>
        </HelmetProvider>
    )
}

export default PageTitle;