import strings from './data/strings.json';

const getString = (key, language = 'EN', fallback = '') => {

    const fullkey= `data.${key}`;
    const keys = fullkey.split('.');

    let result = strings;

    // console.log('These are the keys:');
    // console.log(keys);

    try {
        // console.log('Enter loop');
        for (let i = 0; i < keys.length; i++) {

            // console.log('This is key number: ' + i);
            // console.log(keys[i]);

            if (Array.isArray(result)) {
                // Find the first object in the array that contains the current key
                result = result.find(item => item[keys[i]] !== undefined);

                // console.log('Object found');
                // console.log(result);

                result = result[keys[i]];
            } else {
                result = result[keys[i]];
                // console.log('Not the requested key, current key: ' + result);
            }
            // console.log("No Key Found");
            // if (!result) console.log("No Key Found"); // If the key is not found, return the fallback
            if (!result) return fallback; // If the key is not found, return the fallback
        }

        // console.log(result);

        // Return the value based on the language key (EN or NL)
        return result[0][language] || fallback;
    } catch (error) {
        return fallback; // Return fallback in case of any error
    }
};

export default getString;
