import logo from '../logo.svg';
import '../styles/Main.css';
import { Link } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import React, { Component, useContext } from 'react';
import DataContext from "../DataContext";
import getString from "../getStrings";
import LanguageSwitcher from "../LanguageSwitchButton";
import {useLanguage} from "../LanguageContext";
import desktopLogo from "../assets/logo/desktop.svg";
import "./DesktopNavigation.scss";


function NavigationDesktop() {

    // console.log(JSON.stringify(data, null, 2));
    const { language } = useLanguage();


    // console.log(data);

    return (
        <nav className="NavigationDesktop">

            <div className="logo">
                <Link to="/">
                    <img className="desktopLogo" src={desktopLogo} alt="Logo" />
                </Link>
            </div>

            <div className="pageLinksSection">
                <NavLink
                    to="/about"
                    className={({ isActive }) => isActive ? "active" : ""}
                >
                    {getString('generic.terminologyAndShared.about', language)}
                </NavLink>
                <NavLink
                    to="/portfolio"
                    className={({ isActive }) => isActive ? "active" : ""}
                >
                    {getString('generic.terminologyAndShared.portfolio', language)}
                </NavLink>
                <NavLink
                    to="/skills"
                    className={({ isActive }) => isActive ? "active" : ""}
                >
                    {getString('generic.terminologyAndShared.skills', language)}
                </NavLink>
                <NavLink
                    to="/contact"
                    className={({ isActive }) => isActive ? "active" : ""}
                >
                    {getString('generic.terminologyAndShared.contact', language)}
                </NavLink>
            </div>
            <LanguageSwitcher />

        </nav>
    );
}

export default NavigationDesktop;
