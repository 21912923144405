// Move all imports to the top of the file
import logo from './logo.svg';
import './styles/Main.scss';
import './styles/shared.scss';
import Header from "./Header";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./ScrollToTop";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import React, { lazy, Suspense, useEffect, useLayoutEffect, useState } from "react";
import { RetrieveBrowserLanguage } from "./RetrieveBrowserLanguage";
import { LanguageProvider } from './LanguageContext';
import Icons from "./Icons";
import { Helmet } from "react-helmet";
import getString from './getStrings';
import LanguageSwitcher from "./LanguageSwitchButton";
import CustomIcon from "./Icons";
import FadeWrapper from "./FadeWrapper";
import LoadingSpinner from "./LoadingSpinner";
import 'normalize.css';
import { OverlayProvider } from "./components/Overlay/OverlayContext";
import {AuthProvider} from "./AuthContext";
import ScrollToTopButton from "./components/ScrollToTop/ScrollToTopButton";

// Lazy load components
const Home = lazy(() => import('./pages/Home'));
const Portfolio = lazy(() => import('./pages/Portfolio'));
const Skills = lazy(() => import('./Skills'));
const Contact = lazy(() => import('./pages/Contact'));
const About = lazy(() => import('./About'));
const NotFound = lazy(() => import('./pages/NotFound'));
const language = RetrieveBrowserLanguage();

const AbsentReflectionLogo = lazy(() => import('./pages/portfolioitems/Portfolio-AbsentReflectionLogo'));
const AbsentReflectionWebsite = lazy(() => import('./pages/portfolioitems/Portfolio-AbsentReflectionWebsite'));
const MechanicalHeartLogo = lazy(() => import('./pages/portfolioitems/Portfolio-MechanicalHeartLogo'));
const PokeLibrary = lazy(() => import('./pages/portfolioitems/Portfolio-Pokelibrary'));
const Minute = lazy(() => import('./pages/portfolioitems/Portfolio-Minute'));
const Choklet = lazy(() => import('./pages/portfolioitems/Portfolio-Choklet'));
const Xis = lazy(() => import('./pages/portfolioitems/Portfolio-XIsWebsite'));
const Juno = lazy(() => import('./pages/portfolioitems/Portfolio-Juno'));
const CellularDesign = lazy(() => import('./pages/portfolioitems/Portfolio-Cellular-Design'));
const Isaac = lazy(() => import('./pages/portfolioitems/Portfolio-Isaac'));
const VVD = lazy(() => import('./pages/portfolioitems/Portfolio-VVD'));
const AbsentReflectionSublogos = lazy(() => import('./pages/portfolioitems/Portfolio-AbsentReflectionSublogos'));
const AbsentReflectionIcons = lazy(() => import('./pages/portfolioitems/Portfolio-AbsentReflectionIcons'));


const Project1 = lazy(() => import('./pages/portfolioitems/Portfolio-ProtectedProject1'));



function App() {

    const [showSpinner, setShowSpinner] = useState(true); // State to manage spinner visibility
    const [fadeInInit, setFadeInInit] = useState(false); // State for controlling initial fade-in effect

    useEffect(() => {
        // Use the timer to hide the spinner and trigger fade-in effect
        const timer = setTimeout(() => {
            setShowSpinner(false);
            setFadeInInit(true); // Trigger fade-in after spinner disappears
        }, 3000); // Adjust the delay as needed

        return () => {
            clearTimeout(timer); // Clean up the timer on unmount
        };
    }, []);


    return (
        <LanguageProvider>
            <AuthProvider>
                <Router>
                    <OverlayProvider>
                    <ScrollToTop /> {/* This ensures that it scrolls to top on every route change */}
                    <ScrollToTopButton /> {/* This ensures that it scrolls to top on every route change */}
                    <div className="App">
                        <Header/>
                        {/*<Helmet>*/}
                        {/*    <title>Home - My Website</title>*/}
                        {/*</Helmet>*/}
                        {/*<FadeWrapper> /!* Apply the fade effect for route changes *!/*/}
                        <Suspense fallback={<LoadingSpinner/>}>

                            <div className={`content`}>
                                <Routes>
                                    <Route exact path="/" element={<Home/>}/>
                                    <Route exact path="/about" element={<About/>}/>
                                    <Route exact path="/portfolio" element={<Portfolio/>}/>
                                    <Route exact path="/skills" element={<Skills/>}/>
                                    <Route exact path="/contact" element={<Contact/>}/>

                                    {/*PORTFOLIO ITEMS*/}
                                    <Route exact path="/AbsentReflection-Logo" element={<AbsentReflectionLogo/>}/>
                                    <Route exact path="/AbsentReflection-Website" element={<AbsentReflectionWebsite/>}/>
                                    <Route exact path="/MechanicalHeart-Logo" element={<MechanicalHeartLogo/>}/>
                                    <Route exact path="/PokeLibrary" element={<PokeLibrary/>}/>
                                    <Route exact path="/Minute" element={<Minute/>}/>
                                    <Route exact path="/Choklet" element={<Choklet/>}/>
                                    <Route exact path="/XisFestival" element={<Xis/>}/>
                                    <Route exact path="/Juno" element={<Juno/>}/>
                                    <Route exact path="/CellularDesign" element={<CellularDesign/>}/>
                                    <Route exact path="/Isaac" element={<Isaac/>}/>
                                    <Route exact path="/Vvd" element={<VVD/>}/>
                                    <Route exact path="/AbsentReflection-Sublogos" element={<AbsentReflectionSublogos/>}/>
                                    <Route exact path="/AbsentReflection-Icons" element={<AbsentReflectionIcons />}/>


                                    {/*PROTECTED PORTFOLIO ITEMS*/}
                                    <Route exact path="/Project-1" element={<Project1 />}/>

                                    {/*/!*downloads*!/*/}
                                    {/*<Route exact path="/MechanicalHeart-pdf" element={<MechanicalHeartPDF />} />*/}

                                    <Route path="*" element={<NotFound/>}/>
                                </Routes>
                            </div>

                        </Suspense>
                        {/*</FadeWrapper>*/}
                        <Footer/>

                    </div>

                    </OverlayProvider>
              </Router>
            </AuthProvider>
        </LanguageProvider>
    );
}

export default App;
