import {useLanguage} from "../LanguageContext";
import englishFlag from "./icons/EN.svg";
import dutchFlag from "./icons/NL.svg";
import React from "react";


const AngleAsset = ({position, color, specialClass}) => {

    let colorValue;

    switch (color) {
        case 'blue':
            colorValue = "#0075D3";
            break
        case 'green':
            colorValue = "#00E987";
            break
        case 'white':
            colorValue = "#ffffff";
            break
        default:
            colorValue = "#ff0000";
            break
    }

    // console.log(colorValue);

    return (
        <div className={`DecorativeAsset ` + position + " " +specialClass} >
            {position === "top" ? (
                <svg viewBox="0 0 1440 60" fill={colorValue} xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 59.9999L1440 60L1440 0L0 59.9999Z" fill={colorValue} />
                </svg>
            ) : (
                <svg viewBox="0 0 1440 60" fill={colorValue} xmlns="http://www.w3.org/2000/svg">
                    <path d="M1440 0H0V60L1440 0Z" fill={colorValue}/>
                </svg>
            )}
        </div>
    );
};

export default AngleAsset;