import React, { useEffect } from 'react';

const EmailSplicer = () => {
    useEffect(() => {
        const emailElem = document.querySelector('.email');
        const user = 'info';
        const domain = 'cellular-design';
        const tld = 'nl';
        emailElem.innerHTML = `<span>${user}@${domain}.${tld}</span>`;
    }, []);

    return (
        <div>
            <span className="email"></span>
        </div>
    );
};

export default EmailSplicer;
