import React from 'react';
import {Link, NavLink} from "react-router-dom";
import mobileLogo from "../assets/logo/mobile.svg";
import CustomIcon from "../Icons";
import getString from "../getStrings";


const BottomNavigationLink = ({ title, link, image, alt, color }) => {
    return (
        <NavLink
            to={`/${link}` }
            className={({ isActive }) => isActive ? "active" : ""}
            alt={alt}
        >
            <CustomIcon name={image} color={color} />
            {title}
        </NavLink>
    );
};



// <NavLink
//     to="/about"
//     className={({ isActive }) => isActive ? "active" : ""}
// >
//     {getString('generic.terminologyAndShared.about', language)}
// </NavLink>
// <NavLink
//     to="/portfolio"
//     className={({ isActive }) => isActive ? "active" : ""}
// >
//     {getString('generic.terminologyAndShared.portfolio', language)}
// </NavLink>
// <NavLink
//     to="/skills"
//     className={({ isActive }) => isActive ? "active" : ""}
// >
//     {getString('generic.terminologyAndShared.skills', language)}
// </NavLink>
// <NavLink
//     to="/contact"
//     className={({ isActive }) => isActive ? "active" : ""}
// >
//     {getString('generic.terminologyAndShared.contact', language)}
// </NavLink>

export default BottomNavigationLink;