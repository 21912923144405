import React from "react";
import { useLanguage } from '../../LanguageContext';
import ContentBlock from "../../content/ContentBlock";
import getString from "../../getStrings";
import projectTileSmall from "../../content/projectTileSmall";
import {Link} from "react-router-dom";
import SocialsButtons from "../../navigation/socialsButtons";
import EmailSplicer from "../EmailSplicer/EmailSplicer";

const FooterLinks = ({ sections, links }) => {
    const { language } = useLanguage();
    const pageName = 'generic';

    // console.log('Sections inside FooterLinks:', sections);

    return (
        <div className="footerContent">
            {sections.map((footerItem, footerIndex) => {

                let currentCategory = null;
                currentCategory = footerItem.category;

                let dataSet = footerItem.data;


                // // Switch statement to determine which category we're in
                // switch (footerItem.category) {
                //     case 'data':
                //     currentCategory = footerItem.category;
                //     break;
                //
                //     case 'contact':
                //     currentCategory = footerItem.category;
                //     break;
                //
                //     default:
                //     currentCategory = null;
                //     break;
                // }

                // Skip rendering if currentCategory is null
                if (!currentCategory) return null;

                return (
                    <div key={footerIndex} className={`${dataSet.title} footerInfoSection`}>

                        <h5 className="sectionTitle">{dataSet.title}</h5>

                        {currentCategory === 'data' && (
                            <>

                                {/* Check if withPrefix exists and map over it */}
                                {dataSet.content?.withPrefix && (
                                    <>
                                        {/* Now using the nested map correctly */}
                                        {dataSet.content.withPrefix.map((set, setIndex) => {
                                            return (
                                                <div className="sectionInfoPrefix" key={setIndex}>
                                                    {/* Render content for each "set" */}
                                                    <p className="sectionInfoPrefixLabel">{set.title}</p>
                                                    <p>{set.data}</p>
                                                </div>
                                            );
                                        })}
                                    </>
                                )}

                                {dataSet.content?.withoutPrefix && (
                                    <>
                                        {/* Now using the nested map correctly */}
                                        {dataSet.content.withoutPrefix.map((set, setIndex) => {
                                            return (
                                                <div key={setIndex}>
                                                    {/* Render content for each "set" */}
                                                    <p>{set.title}</p>
                                                </div>
                                            );
                                        })}
                                    </>
                                )}
                            </>
                        )}

                        {currentCategory === 'contact' && (
                            <>

                                {/* Check if content exists */}
                                {dataSet?.content && (
                                    <>
                                        {/* Loop through each key in dataSet.content */}
                                        {Object.keys(dataSet.content).map((key, keyIndex) => {
                                            // console.log(key);
                                            const contentArray = dataSet.content[key]; // Get the array or value from the content object

                                            // Check if the value is an array and map over it
                                            if (Array.isArray(contentArray)) {
                                                return (
                                                    <div key={keyIndex}>
                                                        <h5>{key}</h5> {/* Display the key, like 'withPrefix' or 'withoutPrefix' */}
                                                        {contentArray.map((set, setIndex) => (
                                                            <div key={`${keyIndex}-${setIndex}`}>
                                                                <p key={`${keyIndex}-${setIndex}`}>{set.title}</p>
                                                                <p key={`${keyIndex}-${setIndex++}`}>{set.data}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                );
                                            } else if (typeof contentArray === 'string' || typeof contentArray === 'object') {
                                                // Handle the case where it's not an array (for contact's content.title)
                                                return (
                                                    <div key={keyIndex}>
                                                        <EmailSplicer />
                                                        {/*<p key={keyIndex}>{dataSet.content.title}</p>*/}
                                                    </div>
                                                );
                                            }
                                            return null; // If the value is neither an array nor an object, render nothing
                                        })}
                                    </>
                                )}
                            </>
                        )}

                        {currentCategory === 'socials' && (
                            <SocialsButtons />
                        )}

                        {currentCategory === 'links' && (
                            <>
                                {/* Check if content exists */}
                                {dataSet?.links && (
                                    <>
                                        {/* Loop through each key in dataSet.content */}
                                        {Object.keys(dataSet.links).map((key, keyIndex) => {
                                            // console.log(key);
                                            const contentArray = dataSet.links[key]; // Get the array or value from the content object

                                            // Check if the value is an array and map over it
                                            if (typeof contentArray === 'string' || typeof contentArray === 'object') {
                                                // Handle the case where it's not an array (for contact's content.title)
                                                return (
                                                    <div key={keyIndex}>
                                                        <Link to={contentArray.url}>
                                                            {contentArray.title}

                                                        </Link>
                                                    </div>
                                                );
                                            }
                                            return null; // If the value is neither an array nor an object, render nothing
                                        })}
                                    </>
                                )}
                            </>
                        )}

                    </div>
                )



            })}
        </div>
    );
};

export default FooterLinks;