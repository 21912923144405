// AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { getCookie } from './getCookie'; // Import your getCookie helper
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [isVerified, setIsVerified] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Check authentication with backend when the component mounts
    useEffect(() => {
        const checkAuthentication = async () => {
            const passwordToken = getCookie('passwordToken');

            if (!passwordToken) {
                setError('No access token found');
                setLoading(false);
                return;
            }

            try {
                const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
                const apiUrl = isLocalhost
                    ? 'http://localhost/backend_api.php'
                    : 'https://www.cellular-design.com/backend_api.php';

                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        endpoint: 'verifySessionToken',
                        passwordToken: passwordToken,
                    }),
                    credentials: 'include',
                });

                const result = await response.json();

                if (result.status === 'success') {
                    setIsVerified(true);
                } else {
                    setError('Authentication failed');
                }
            } catch (err) {
                setError('Error verifying authentication');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        checkAuthentication();
    }, []);

    const verifyAuth = () => {
        setIsVerified(true);
    };

    const resetAuth = () => {
        setIsVerified(false);
    };

    return (
        <AuthContext.Provider value={{ isVerified, verifyAuth, resetAuth, loading, error }}>
            {children}
        </AuthContext.Provider>
    );
};
